import React, { useEffect } from 'react';
import Head from 'next/head';
import { getInitialLocale } from '../translations/getInitialLocale';

const Index = () => {
  useEffect(() => {
    window.location.href = `/${getInitialLocale()}`;
  }, []);

  return (
    <Head>
      <>
        <title>Deevent - Discover the best places of Switzerland</title>
        <meta
          name="description"
          content="Discover the best places of Switzerland"
        />
        <meta property="og:title" content="Deevent" key="title" />
        <meta
          property="og:description"
          content="Discover the best places of Switzerland"
        />
        <meta property="og:url" content="https://deevent.app/" />
        <meta property="og:image" content="https://deevent.app/mountain.jpg" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="825" />
        <meta property="og:type" content="article" />
      </>
    </Head>
  );
};

export default Index;
